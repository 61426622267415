<template>
  <div>
    <div :class="$style.chat">
      <Title text="Чаты" position="right" />
      <div :class="$style.chat__header">
        <date-picker v-model="date" range :lang="lang" placeholder="Дата" />

        <Input :class="$style.chat__header_input" placeholder="Поиск" v-model="filterValue" />
        <Button :class="$style.chat__header_input" @click="createNewChat()" type="tertiary"
          >Начать чат</Button
        >
      </div>
      <div :class="$style.table">
        <div :class="$style.table__header">
          <div
            :class="$style.table__header_column"
            v-for="headerColumn in headerColumns"
            :key="headerColumn.id"
          >
            {{ headerColumn.title }}
            <img
              v-if="headerColumn.value"
              src="@/assets/icons/arrow.svg"
              alt="arrow"
              @click="sortChats(headerColumn.value)"
            />
          </div>
        </div>
        <div :class="$style.table__content">
          <div
            v-for="chat in totalItems"
            :key="chat.id"
            :class="[
              $style.table__content_wrapper,
              !chat.viewed ? $style.table__content_wrapper_active : ''
            ]"
          >
            <div :class="$style.table__content_column">
              {{ chat.id }}
            </div>
            <div :class="$style.table__content_column">
              {{ moment(chat.createdAt).format('DD-MM-YYYY  LT') }}
            </div>
            <div :class="$style.table__content_column">
              {{ chat.statusId }}
            </div>
            <div :class="$style.table__content_column">
              {{ chat.user.phone }}
            </div>
            <div :class="$style.table__content_column">
              {{ chat.user.id }}
            </div>
            <div :class="$style.table__content_column">
              <Button type="table" @click="connectToChat(chat.id)"> Перейти к чату </Button>
            </div>

          </div>
          <div :class="$style.table__content_wrapperLine">
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />

          </div>
        </div>
      </div>
    </div>
    <pagination
      :pageCount="pageCount"
      :prevText="''"
      :click-handler="onPageChange"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      v-show="chats.length > 10"
      :initial-page="this.$route.query.page - 1"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Moment from 'moment'
import orderBy from 'lodash/orderBy'

import Title from '@/basic/Title'
import Input from '@/basic/Input'
import Button from '@/basic/Button'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'

import paginationMixin from '@/mixins/pagination.mixin'
import 'vue2-datepicker/index.css'

export default {
  name: 'Chats',
  components: {
    Title,
    DatePicker,
    Input,
    Button
  },
  data() {
    return {
      lang: ru,
      date: [],
      filterValue: '',
      sortMethod: 'asc',
      isActiveRow: false,
      headerColumns: [
        {
          id: 1,
          value: 'id',
          title: 'id'
        },
        {
          id: 2,
          value: 'createdAt',
          title: 'Дата'
        },
        {
          id: 3,
          value: 'statusId',
          title: 'Статус'
        },
        {
          id: 4,
          value: 'initiator',
          title: 'Инициатор'
        },
        {
          id: 5,
          value: 'initiatorId',
          title: 'id Инициатора'
        },
        {
          id: 6,
          value: '',
          title: ''
        },
        /*{
          id: 7,
          value: 'employeeId',
          title: 'Менеджер'
        }

         */
      ]
    }
  },
  mixins: [paginationMixin],

  mounted() {
    this.clearChat()
    this.$socket.io.opts.extraHeaders['x-access-token'] = this.authToken
    this.$socket.open()
    this.$socket.emit('dashboard-chats-list', {}, (data) => {
      if (data) {
        console.log('[dashboard-chats-list]: ', data)
        this.setChats(data.result)
        this.setupPagination(this.filteredChats)
      }
    })
  },
  watch: {
    filterValue: {
      handler() {
        this.setupPagination(this.filteredChats)
      }
    },
    date: {
      handler() {
        this.setupPagination(this.filteredChats)
      }
    },
    notifications: {
      handler() {
        console.log('[notifications]: ', this.notifications)
      }
    }
  },
  sockets: {
    'server-notifications-send'(data) {
      if (data.type.id === 21) {
        this.$socket.emit('dashboard-chats-list', {}, (data) => {
          if (data && data.success) {
            console.log('[dashboard-chats-list]: ', data)
            this.setChats(data.result)
            this.setupPagination(data.result)
          }
        })

        if (!this.$route.path.includes('chats')) {
          this.setIsReceivedChatMessage(true)
        }
      } else {
        return
      }
    }
  },
  computed: {
    ...mapGetters({
      authToken: 'authToken',
      notifications: 'notifications',
      chats: 'chats'
    }),
    moment() {
      return Moment
    },
    startDate() {
      return this.date && this.moment(this.date[0]).format('YYYY-MM-DD LT')
    },
    endDate() {
      return this.date && this.moment(this.date[1]).format('YYYY-MM-DD LT')
    },
    filteredByDateChats() {
      if (
        !this.date.length ||
        (this.startDate === 'Invalid date' && this.endDate === 'Invalid date')
      ) {
        return this.chats
      }
      return this.chats?.filter(
        (chat) => {
          return this.moment(chat.createdAt).isAfter(this.moment(this.startDate).format("YYYY-MM-DD") ) &&
          this.moment(chat.createdAt).isBefore(this.moment(this.endDate).add(1, 'days').format("YYYY-MM-DD"))

        }
      )
    },
    filteredChats() {
      return this.filteredByDateChats?.filter(
        (chat) => {
          return chat.user.phone?.toString().indexOf(this.filterValue) >= 0 ||
          chat.id.toString().indexOf(this.filterValue) >= 0 ||
          chat.status.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0
        }
      )
    }
  },

  methods: {
    ...mapMutations([
      'setChat',
      'setNotifications',
      'filterNotifications',
      'setChats',
      'clearChat'
    ]),
    connectToChat(chatId) {
      this.$socket.emit('dashboard-chats-enter', { chatId }, (data) => {
        if (data && data.success) {
          this.setChat(data.result)
          console.log('[dashboard-chats-enter]: ', data.result)
          this.$router.push(`chats/${chatId}`)
        }
      })
    },
    createNewChat() {
      this.$router.push(`chats/new`)
    },
    sortChats(sortValue) {
      this.toggleSortMethod()
      this.totalItems = orderBy(this.totalItems, sortValue, this.sortMethod)
    },
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    },
    setCurrentPage(pageNumber) {
      this.currentPage = pageNumber
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.chat {
  &__header {
    display: flex;
    margin: 0 0 2rem;
    &_input {
      width: 15rem;
      margin: 0 4rem 0 0;
    }
  }

  .table {
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      display: flex;
      justify-content: space-between;
      height: 2.5rem;
      border-bottom: 1px solid $table-border;

      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 5.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;
      &_active {
        background: red;
      }

      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
        &_active {
          background: red;
        }
      }

      &_column {
        display: flex;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 5.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }

        &_active {
          background: $red;
          border-right: 1px solid $table-border;
        }
      }

      &_wrapperLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 5.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
